import React from 'react';

import { TWITTER_HANDLE } from 'common/configuration/constants';
import { openPopup } from 'common/module/ui/share-btn';

import ShareButton, { Network, Props as ShareButtonProps } from './ShareButton';

type Props = Omit<ShareButtonProps, 'url' | 'network'> & {
  message?: string;
  link?: string;
};

const TwitterShareButton = ({ message, link, ...rest }: Props) => {
  let url = `http://twitter.com/intent/tweet?url=${encodeURIComponent(
    link ?? ''
  )}&via=${TWITTER_HANDLE}`;

  if (message) url += `&text=${encodeURIComponent(message)}`;

  return (
    <ShareButton
      {...rest}
      onClick={e => {
        e.preventDefault();
        openPopup(url);
      }}
      network={Network.TWITTER}
    >
      Twitter
    </ShareButton>
  );
};

export default TwitterShareButton;
