import isNumeric from 'common/tools/string/isNumeric';

import {
  SocialAction,
  Movie,
  Series,
  Season,
  Episode,
  Program,
  UserReview,
  Entity,
  WantToSee,
  Opinion,
  Helpful,
  Unhelpful,
  SeenIt,
  EntityTypename
} from 'website/types';
import { isSocialAction } from 'website/types';

import { DataState } from '.';
import {
  JsEntity,
  MovieJsEntity,
  ProgramJsEntity,
  SeasonJsEntity,
  SeriesJsEntity,
  EpisodeJsEntity,
  SeasonGraphStatus,
  SeasonStatus,
  GraphNode,
  SeriesGraphEntity,
  MovieGraphEntity,
  SeasonGraphEntity,
  EpisodeGraphEntity,
  ProgramGraphEntity,
  GraphOpinion,
  GraphUserEntityLeaf,
  GraphSocialAction,
  UserEntityLeaf,
  isGraphOpinion,
  UserReviewJsEntity
} from './types';

const seasonStatusMapper = (seasonStatus?: SeasonGraphStatus): SeasonStatus => {
  switch (seasonStatus) {
    case 'TO_COME':
      return 122002;
    case 'PILOT':
      return 122003;
    case 'IN_PROGRESS':
      return 122004;
    case 'CANCELLED':
      return 122005;
    case 'ENDED':
      return 122006;
    default:
      return 122004;
  }
};

const getUserAffinity = (entity: MovieGraphEntity | SeriesGraphEntity) =>
  entity?.userAffinity || { reason: [], affinityScore: null };

export const typeNameToNodeNameMapper = (typeName: string): string => {
  switch (typeName) {
    default:
      return typeName[0].toLowerCase() + typeName.slice(1);
  }
};

export const actionIsRecent = (action: SocialAction): boolean => {
  const now = new Date();
  const actionDate = new Date(action.date);
  let diff = actionDate.getTime() - now.getTime();
  diff = Math.ceil(diff / (1000 * 3600));
  return diff >= -5; // 5 hours
};

const isMovieJsEntity = (
  jsEntity: JsEntity,
  typename: string
): jsEntity is MovieJsEntity => jsEntity && typename === 'Movie';

export const isSeriesJsEntity = (
  jsEntity: JsEntity,
  typename: string
): jsEntity is SeriesJsEntity => jsEntity && typename === 'Series';

export const isSeasonJsEntity = (
  jsEntity: JsEntity,
  typename: string
): jsEntity is SeasonJsEntity => jsEntity && typename === 'Season';

const isEpisodeJsEntity = (
  jsEntity: JsEntity,
  typename: string
): jsEntity is EpisodeJsEntity => jsEntity && typename === 'Episode';

const isProgramJsEntity = (
  jsEntity: JsEntity,
  typename: string
): jsEntity is ProgramJsEntity => jsEntity && typename === 'Program';

const isUserReviewJsEntity = (
  jsEntity: JsEntity,
  typename: string
): jsEntity is UserReviewJsEntity => jsEntity && typename === 'UserReview';

export const jsEntityMapper = (jsEntities: JsEntity[]) => {
  const map: Entity[] = [];

  for (const jsEntity of jsEntities) {
    const { id } = jsEntity;
    const decodeId = window.atob(id).split(':');
    const typename = decodeId[0];
    const legacyId = Number(decodeId[1]);
    const common = {
      id: id,
      legacyId: legacyId
    };

    if (isMovieJsEntity(jsEntity, typename)) {
      const mapped: Movie = {
        ...common,
        typename: EntityTypename.Movie,
        title: jsEntity.title,
        poster: null,
        wantToSeeCount: jsEntity.social
          ? jsEntity.social.user_note_i_want_to_see_count
          : 0,
        isComingSoon: jsEntity.flags ? jsEntity.flags.isComingSoon : false,
        releaseDate: jsEntity.releaseDate,
        userAffinity: { reason: [], affinityScore: null },
        productionYear: jsEntity.productionYear,
        hasMandatoryReview: !!jsEntity.rules?.rating?.has_mandatory_review
      };
      map.push(mapped);
    } else if (isSeriesJsEntity(jsEntity, typename)) {
      // we will create a list of "season" entities from
      // the graph data retrieved from the Series entity
      const seasons =
        jsEntity.seasons?.nodes?.reduce((acc: SeasonJsEntity[], season) => {
          if (isNumeric(season.number)) {
            const seasonItem = {
              id: window.btoa(`Season:${season.internalId}`),
              number: season.number,
              season_status: seasonStatusMapper(season.status)
            };
            acc.push(seasonItem);
          }
          return acc;
        }, []) ?? [];

      const mapped: Series = {
        ...common,
        typename: EntityTypename.Series,
        title: jsEntity.title,
        poster: null,
        isComingSoon: jsEntity.flags ? jsEntity.flags.isComingSoon : false,
        seasons:
          jsEntity.broadcasted_seasons ?? seasons.map(season => season.id),
        releaseDate: jsEntity.releaseDate,
        userAffinity: { reason: [], affinityScore: null }
      };
      map.push(mapped);
      // we will inject all the "seasons" entities created above.
      const seasonsMapped = jsEntityMapper(seasons);
      map.push(...seasonsMapped);
    } else if (isSeasonJsEntity(jsEntity, typename)) {
      const mapped: Season = {
        ...common,
        typename: EntityTypename.Season,
        number: jsEntity.number,
        status: jsEntity.season_status
      };
      map.push(mapped);
    } else if (isEpisodeJsEntity(jsEntity, typename)) {
      const mapped: Episode = {
        ...common,
        typename: EntityTypename.Episode,
        title: jsEntity.title
      };
      map.push(mapped);
    } else if (isProgramJsEntity(jsEntity, typename)) {
      const mapped: Program = {
        ...common,
        typename: EntityTypename.Program,
        title: jsEntity.title
      };
      map.push(mapped);
    } else if (isUserReviewJsEntity(jsEntity, typename)) {
      const mapped: UserReview = {
        ...common,
        typename: EntityTypename.UserReview
      };
      map.push(mapped);
    } else {
      throw new Error(`unknown typename ${typename}`);
    }
  }

  return map;
};

const baseMapper = (
  entity: GraphNode
): { id: string; typename: string; legacyId: number } => {
  const { id, __typename } = entity || {};
  if (!id || !__typename) throw new Error('invalid data');
  return {
    id,
    typename: __typename,
    legacyId: Number(window.atob(id).split(':')[1])
  };
};

const nodeMapper = (node: GraphNode): Entity => {
  let mappedNode = baseMapper(node);
  switch (mappedNode.typename) {
    case EntityTypename.Movie: {
      const movie = node as MovieGraphEntity;
      (mappedNode as Movie) = {
        ...mappedNode,
        isComingSoon: !!movie.flags?.isComingSoon,
        poster: movie.poster?.path ?? null,
        productionYear: movie.data?.productionYear ?? null,
        releaseDate: movie.releases?.[0]?.releaseDate?.date ?? null,
        title: movie.title ?? '',
        typename: EntityTypename.Movie,
        userAffinity: getUserAffinity(movie),
        wantToSeeCount: null,
        hasMandatoryReview: false
      };
      return mappedNode as Movie;
    }
    case EntityTypename.Series: {
      const series = node as SeriesGraphEntity;
      (mappedNode as Series) = {
        ...mappedNode,
        typename: EntityTypename.Series,
        title: series.title,
        poster: series.poster
          ? series.poster.file_name ?? series.poster.path
          : null, // movie request return poster.path but series request return poster.file_name
        isComingSoon: series.flags ? series.flags.isComingSoon : false,
        releaseDate: series.originalBroadcast
          ? series.originalBroadcast.firstAiredDate.date
          : null,
        seasons: [],
        userAffinity: getUserAffinity(series)
      };
      return mappedNode as Series;
    }
    case EntityTypename.Season: {
      const season = node as SeasonGraphEntity;
      (mappedNode as Season) = {
        ...mappedNode,
        typename: EntityTypename.Season,
        number: season.number,
        status: seasonStatusMapper(season.status)
      };
      return mappedNode as Season;
    }
    case EntityTypename.Episode: {
      const episode = node as EpisodeGraphEntity;
      (mappedNode as Episode) = {
        ...mappedNode,
        title: episode.title
      } as Episode;
      return mappedNode as Episode;
    }
    case EntityTypename.Program: {
      const program = node as ProgramGraphEntity;
      (mappedNode as Program) = {
        ...mappedNode,
        typename: EntityTypename.Program,
        title: program.title
      };
      return mappedNode as Program;
    }
    case EntityTypename.UserReview: {
      (mappedNode as UserReview) = {
        ...mappedNode,
        typename: EntityTypename.UserReview
      };
      return mappedNode as UserReview;
    }
    default: {
      throw new Error(`unknown typename ${mappedNode.typename}`);
    }
  }
};

export const opinionMapper = (
  opinion: GraphOpinion,
  mappedOpinion: Opinion
): Opinion => {
  return {
    ...mappedOpinion,
    rating: opinion.content ? opinion.content.rating : null,
    review: opinion.content ? opinion.content.review : null,
    status: opinion.content ? opinion.content.status : null
  };
};

export const actionMapper = <T extends SocialAction>(
  action: GraphSocialAction | null
): T | null => {
  if (action === null) return null;
  const mappedAction = baseMapper(action);
  let socialAction = mappedAction as SocialAction;
  if (action.relatedEntity) {
    socialAction.relatedEntity = action.relatedEntity.id;
  }

  socialAction.date = new Date(action.updatedAt);
  socialAction.isDeleted = false;

  if (isGraphOpinion(action)) {
    socialAction = opinionMapper(action, socialAction as Opinion);
  }

  return socialAction as T;
};

export const userEntityLeafMapper = (
  userEntityLeaf: GraphUserEntityLeaf
): UserEntityLeaf => {
  const entity = nodeMapper(userEntityLeaf.entity);
  const wantToSee = actionMapper<WantToSee>(userEntityLeaf.wantToSee);
  const opinion = actionMapper<Opinion>(userEntityLeaf.opinion);
  const helpful = actionMapper<Helpful>(userEntityLeaf.helpful);
  const unhelpful = actionMapper<Unhelpful>(userEntityLeaf.unhelpful);
  const seenIt = actionMapper<SeenIt>(userEntityLeaf.seenIt);

  return { entity, wantToSee, opinion, helpful, unhelpful, seenIt };
};

export const titaniaMapper = (titaniaEntity: any): Entity => {
  // Transforming Titania parsed data to satisfy our Graph parser
  const { type_name: __typename, poster, releases, ...rest } = titaniaEntity;
  return nodeMapper({
    ...rest,
    __typename,
    poster: {
      path: poster ? poster.path ?? poster.file_name : null // movie request return poster.path but series request return poster.file_name
    },
    releases: releases.filter(
      ({ name }: { name: string }) => name === 'Released'
    ),
    id: window.btoa(`${__typename}:${titaniaEntity.internalId}`)
  });
};

type ActionNames = 'opinion' | 'wantToSee' | 'seenIt' | 'helpful' | 'unhelpful';
const getActionForEntity = <T extends SocialAction>(
  entityId: string,
  actionName: ActionNames,
  state: DataState
): T | null => {
  const actionId = state.actionsPerEntity[entityId]?.[actionName];
  const action = actionId ? state.all[actionId] : null;
  return action && isSocialAction(action) && !action.isDeleted
    ? (action as T)
    : null;
};

export const getOpinionForEntity = (entityId: string, state: DataState) =>
  getActionForEntity<Opinion>(entityId, 'opinion', state);

export const getSeenItForEntity = (entityId: string, state: DataState) =>
  getActionForEntity<SeenIt>(entityId, 'seenIt', state);

export const getWantToSeeForEntity = (entityId: string, state: DataState) =>
  getActionForEntity<WantToSee>(entityId, 'wantToSee', state);

export const getHelpfulForEntity = (entityId: string, state: DataState) =>
  getActionForEntity<Helpful>(entityId, 'helpful', state);

export const getUnhelpfulForEntity = (entityId: string, state: DataState) =>
  getActionForEntity<Unhelpful>(entityId, 'unhelpful', state);
