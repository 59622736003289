import socialActionTracking from 'common/tracking/socialActionTracking';

export const trackUserTheater = (eventAction: string) => {
  // since data past to GQL
  // no data match entity status

  socialActionTracking(
    'fav_theater',
    eventAction === 'remove' ? 'delete' : eventAction
  );
};
