import { EventEmitter } from 'events';

import { Maybe } from 'types/graphql-api.generated';

import type {
  TrackingEventEmitter,
  TrackingEventNames
} from 'common/constants/trackingEventsNames';
import type { ModalNames } from 'common/module/ui/lazy-modal/constant-types';
import type { PromoteSignupModalParams } from 'common/module/ui/lazy-modal/types';

import type { AccountNeedsConfirmationModalParams } from 'website/services/modal/types';

import { MODAL_REQUEST_ERROR } from 'userspace/module/notifications';
import { addErrorNotification } from 'userspace/module/notifications/error';

export enum EventsTypes {
  ADS_CHANGE_PAGE_SITE = 'custom-events/ads-change-page-site',
  ADS_POSITIONS_READY = 'custom-events/ads-positions-ready',
  ADS_READY = 'custom-events/ads-ready',
  ASYNCHRONOUS_PAGE_CHANGE = 'custom-events/async-load',
  CLOSE_MODAL = 'custom-events/close-modal',
  DESOBFUSCATION_DONE = 'custom-events/desobfuscation-done',
  EMERGENCE_BANNER_ON = 'custom-event/emergence-banner-on',
  FACEBOOK_LIKE = 'custom-events/facebook-like',
  GA_LOADED = 'custom-events/ga_loaded',
  GEOLOCATION_AVAILABLE = 'custom-events/geolocation-available',
  JAD_LOADED = 'custom-events/jad-loaded',
  LAZY_REVALIDATE = 'custom-events/lazy-revalidate',
  LOAD_DEFER_ASSETS = 'custom-events/load-defer-assets',
  LOGIN_REQUEST = 'custom-events/login-request',
  LOGIN_SUCCESS = 'custom-events/login-success',
  MQ_STATE = 'custom-events/mq-change',
  OPEN_MODAL = 'custom-events/open-modal',
  PASSIVE_LOGIN_DONE = 'custom-events/passive-login-done',
  PLAYER_EMERGENCE_INITIALIZED = 'custom-events/player-emergence-initialized',
  PLAYER_EMERGENCE_INSERTED = 'custom-events/player-emergence-inserted',
  PLAYER_EMERGENCE_PAUSED = 'custom-events/player-emergence-paused',
  PLAYER_EMERGENCE_RESUMED = 'custom-events/player-emergence-resumed',
  PLAYER_EXPAND_OFF = 'custom-events/player-expand-off',
  PLAYER_EXPAND_ON = 'custom-events/player-expand-on',
  PLAYER_MINIMIZED = 'custom-events/player-minimized',
  PLAYER_READY = 'custom-events/player-ready',
  PLAYER_UNMINIMIZED = 'custom-events/player-unminimized',
  RECAPTCHA_LOADED = 'custom-events/recaptcha-loaded',
  ROLLER_GETCURRENT_ITEM = 'custom-events/roller-getcurrentitem',
  ROLLER_GETNEW = 'custom-events/roller-getnew',
  ROLLER_REVALIDATE = 'custom-events/roller-revalidate',
  SLIDE_CHANGED = 'custom-events/slide-changed',
  SWIPE = 'custom-events/swipe',
  UPDATE_USER_PROFILE = 'custom-events/update-user-profile',
  VIDEO_IS_PLAYING = 'custom-events/video-is-playing',
  VIDEO_IS_STOPPED = 'custom-events/video-is-stopped'
}

export type AdsChangePageSitePayloadParams = {
  advertisementTargets?: string[];
  newSitePage: string;
  oldSitePage: string;
};

interface BaseEventEmitter extends EventEmitter {
  on(
    event:
      | EventsTypes.ADS_POSITIONS_READY
      | EventsTypes.ADS_READY
      | EventsTypes.ASYNCHRONOUS_PAGE_CHANGE
      | EventsTypes.EMERGENCE_BANNER_ON
      | EventsTypes.JAD_LOADED
      | EventsTypes.LAZY_REVALIDATE
      | EventsTypes.LOGIN_REQUEST
      | EventsTypes.LOGIN_SUCCESS
      | EventsTypes.PLAYER_EMERGENCE_INITIALIZED
      | EventsTypes.PLAYER_EMERGENCE_INSERTED
      | EventsTypes.PLAYER_EMERGENCE_PAUSED
      | EventsTypes.PLAYER_EMERGENCE_RESUMED
      | EventsTypes.ROLLER_REVALIDATE
      | EventsTypes.UPDATE_USER_PROFILE,
    fn: (...args: any[]) => void
  ): this;
  on(
    event: EventsTypes.ADS_CHANGE_PAGE_SITE,
    fn: (payload: AdsChangePageSitePayloadParams) => void
  ): this;
  on(
    event: EventsTypes.CLOSE_MODAL,
    fn: (modalName: ModalNames, params?: any) => void
  ): this;
  on(event: EventsTypes.MQ_STATE, fn: (mqState: number) => void): this;
  on(
    event: EventsTypes.OPEN_MODAL,
    fn: (
      modalName: ModalNames.ACCOUNT_NEEDS_CONFIRMATION,
      params: AccountNeedsConfirmationModalParams
    ) => void
  ): this;
  on(
    event: EventsTypes.OPEN_MODAL,
    fn: (
      modalName: ModalNames.PROMOTE_SIGNUP,
      params: PromoteSignupModalParams
    ) => void
  ): this;
  on(
    event: EventsTypes.OPEN_MODAL,
    fn: (modalName: ModalNames.PROMOTE_AFFINITY) => void
  ): this;
  on(
    event: EventsTypes.PLAYER_READY,
    fn: (playerStartedId: string) => void
  ): this;
  on(
    event: EventsTypes.ROLLER_GETCURRENT_ITEM,
    fn: (roller: Element) => any
  ): this;
  on(
    event: EventsTypes.SWIPE,
    fn: (args: [element: Element | undefined, direction: Maybe<string>]) => void
  ): this;
  on(event: keyof typeof TrackingEventNames, fn: (...args: any[]) => any): this;
  once(event: EventsTypes.LOAD_DEFER_ASSETS, fn: () => void): this;
  on(event: typeof MODAL_REQUEST_ERROR, fn: typeof addErrorNotification): this;
}

type MyEventEmitter = BaseEventEmitter & TrackingEventEmitter;

const eventEmitter: MyEventEmitter = new EventEmitter();
eventEmitter.setMaxListeners(0);

export default eventEmitter;
