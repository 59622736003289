import intersection from 'lodash/intersection';
import union from 'lodash/union';

import {
  Company,
  EntityCompany,
  Maybe,
  PartialDate,
  ReleaseTag,
  ReleaseTagType
} from 'types/graphql-api.generated';

import { getCurrentUser } from 'common/api/GraphApi';
import { PartialDate as PartialDateClass } from 'common/api/PartialDate';
import { PartialDatePrecision } from 'common/api/PartialDateType';
import { loginSuccessMapper } from 'common/reducers/user';

export type EntityWithReleases = {
  releases?: Array<{
    releaseDate?: Maybe<Pick<PartialDate, 'date' | 'precision' | '__typename'>>;
    releaseTags?: Maybe<Pick<ReleaseTag, 'tagTypes' | '__typename'>>;
    companies?: Array<
      Pick<EntityCompany, '__typename'> & {
        company?: Maybe<Pick<Company, 'name' | '__typename'>>;
      }
    >;
    __typename?: 'MovieRelease' | 'SeriesRelease';
  } | null> | null;
};

const squashEdges = (
  data: any[] | Record<string, any>
): Record<string, any> => {
  if (typeof data !== 'object') {
    return data;
  }

  // returns a copy of the array without the intermediate nodes
  if (Array.isArray(data)) {
    return data.map(item => {
      if (item.node) {
        // remove the intermediate node
        return squashEdges(item.node);
      }
      return squashEdges(item);
    });
  }

  // returns a copy of the object without intermediate nodes
  const simplifyData: Record<string, any> = {};
  let key;
  for (key in data) {
    if (data[key] && data[key].edges) {
      // delete the intermediate node accessing the array
      simplifyData[key] = squashEdges(data[key].edges);
    } else {
      simplifyData[key] = data[key] ? squashEdges(data[key]) : null;
    }
  }

  return simplifyData;
};

export const simplifyGraphData = (response: {
  data: any[] | Record<string, any>;
}) => {
  if (!response || !response.data) {
    return {};
  }

  return squashEdges(response.data);
};

// The eligible release types.
// Returned release types order will be the same as this one.
//
// Attention !!!
// This list is also defined and used for php in the file
//
// src/GraphBundle/Helper/Release/ReleaseHelper.php
//
// If you change this list, you will have to change it in the PHP file as well.
const eligibleReleaseTagTypes = [
  ReleaseTagType.Theater,
  ReleaseTagType.OnlineVod,
  ReleaseTagType.OnlineVodest,
  ReleaseTagType.Physical,
  ReleaseTagType.PhysicalBluray,
  ReleaseTagType.PhysicalDvd,
  ReleaseTagType.OnlineSvod,
  ReleaseTagType.Replay
];

/**
 * Get first release date, with release types
 *
 * Attention !!!
 * This method is a copy of the one defined in the file
 *
 * src/GraphBundle/Helper/Release/ReleaseHelper.php
 *
 * Please keep the same logic in both files.
 *
 * @param {MovieReleasesFragmentFragment} entity
 */
export const getFirstReleaseInfos = (entity?: EntityWithReleases) => {
  // Get the first release date and filter releases list matching it
  const firstRelease: {
    date: PartialDateClass | null;
    releaseTypes: ReleaseTagType[];
    companies: string[];
  } = {
    date: null,
    releaseTypes: [],
    companies: []
  };

  (entity?.releases ?? []).forEach(release => {
    if (release?.releaseDate?.date) {
      const releaseDate = new PartialDateClass(
        release.releaseDate.date,
        release.releaseDate.precision as PartialDatePrecision
      );

      // Find the first release date
      if (!firstRelease.date || releaseDate.date < firstRelease.date.date) {
        firstRelease.date = releaseDate;
        firstRelease.releaseTypes = intersection(
          release.releaseTags?.tagTypes ?? [],
          eligibleReleaseTagTypes
        );
        firstRelease.companies = (release.companies ?? []).map(
          company => company.company?.name ?? ''
        );
      } else if (
        firstRelease.date.date.getTime() === releaseDate.date.getTime()
      ) {
        // Merge the releaseTypes if the date is the same
        firstRelease.releaseTypes = union(
          firstRelease.releaseTypes,
          intersection(
            release.releaseTags?.tagTypes ?? [],
            eligibleReleaseTagTypes
          )
        );
        firstRelease.companies = union(
          firstRelease.companies,
          (release.companies ?? []).map(company => company.company?.name ?? '')
        );
      }
    }
  });

  // Sort the releaseTypes to maintain the order defined in eligibleReleaseTagTypes
  firstRelease.releaseTypes.sort(
    (type1, type2) =>
      eligibleReleaseTagTypes.indexOf(type1) -
      eligibleReleaseTagTypes.indexOf(type2)
  );

  return firstRelease;
};

export const getParsedUserData = async () => {
  const response = await getCurrentUser();
  return loginSuccessMapper(response);
};
