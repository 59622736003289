import socialActionTracking from 'common/tracking/socialActionTracking';

export type TrackOpinionContextType = {
  eventLabel: string | string[];
  entity: { legacyId: number; title: string; typename: string };
  socialActionPlacement: string;
};

const sendHit = (
  eventLabel: string,
  eventAction: string,
  { entity: { legacyId, title, typename } }: TrackOpinionContextType
) =>
  socialActionTracking(
    eventLabel === 'reviews' ? 'review' : 'rate',
    eventAction,
    {
      internalId: legacyId,
      title,
      typename
    }
  );

export const trackOpinion = (
  eventAction: string,
  { context }: { context: TrackOpinionContextType }
) => {
  // one event could be sent for 2 trackings:
  // review modal sends review and rating tracking
  if (Array.isArray(context.eventLabel)) {
    return context.eventLabel.forEach(label =>
      sendHit(label, eventAction, context)
    );
  }

  return sendHit(context.eventLabel, eventAction, context);
};
