import { Experience, Projection, Sound } from 'types/graphql-api.generated';

import {
  ReservationTrackingMovie,
  ReservationTrackingShowtime,
  ReservationTrackingTheater,
  TheaterReservationTracking
} from 'common/constants/trackingEventsNames';
import getShowtimeTechno from 'common/tools/showtime/getShowtimesTechno';
import getTicketingUrl from 'common/tools/showtime/getTicketingUrl';
import pad from 'common/tools/string/pad';
import slugify from 'common/tools/string/slugify';
import { GA4Entity } from 'common/tracking/helpers';
import { hit as hit_jan } from 'common/tracking/jan';

const getTime = (dateString: string | number | Date) => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${date.getFullYear()}-${pad(month)}-${pad(day)}-${pad(hours)}-${pad(
    minutes
  )}`;
};
export const getReservationTrackingGA4 = (
  showtime?: ReservationTrackingShowtime,
  theater?: ReservationTrackingTheater,
  movie?: ReservationTrackingMovie,
  fullTracking = false
) => {
  let trackingData: Record<string, any> = fullTracking
    ? {}
    : {
        geolocated: false
      };

  if (theater) {
    trackingData = {
      ...trackingData,
      theater_city: slugify(theater.location?.city),
      theater_entity: GA4Entity(theater.internalId, theater.name),
      theater_region: slugify(theater.location?.region),
      theater_type: theater.theaterCircuits
        ? GA4Entity(
            theater.theaterCircuits.internalId,
            theater.theaterCircuits.name
          )
        : '81007|independant'
    };
    if (fullTracking) {
      trackingData.theater_id = theater.internalId;
      trackingData.theater_name = slugify(theater.name);
      trackingData.theater_zip_code = theater.location?.zip;
    } else {
      trackingData.geolocated = true;
    }
  }

  if (showtime) {
    trackingData.showtime_date_hour = showtime.startsAt
      ? getTime(showtime.startsAt)
      : 'undefined';

    if (fullTracking) {
      const techno = getShowtimeTechno(showtime);

      trackingData.showtimes_techno = techno.join('|').toLowerCase();
      // linked to the icons displayed below the showtimes
      // SHOWTIMES_EXPERIENCES_ICONS_CLASSES
      // (app/Resources/src-front/js/app/common/constants/Showtimes.ts)
      trackingData.has_dolby = [
        Experience.DolbyAtmos,
        Experience.DolbyCinema,
        Sound.Atmos
      ].some(experience => techno.includes(experience));
      trackingData.has_imax = [
        Projection.Imax,
        Projection.Imax_3D,
        Projection.Imax_70Mm
      ].some(projection => techno.includes(projection));

      trackingData.theater_is_allocine_vad = !!getTicketingUrl(
        showtime.data?.ticketing
      );
    }
  }

  if (movie) {
    trackingData.movie_entity = GA4Entity(movie.internalId, movie.title);
    if (fullTracking) {
      trackingData.movie_id = movie.internalId;
      trackingData.movie_title = slugify(movie.title);
      trackingData.movie_genres = movie.genres?.join('|').toLowerCase();
    }
  }

  return trackingData;
};

const SHOWTIMES_BUTTON_CLICK = 'showtimes_button_click';

const trackReservationClick = ({
  showtime,
  theater,
  movie,
  ga4event,
  ga4data,
  ga4dataOverwrite
}: TheaterReservationTracking) => {
  const ga4EventName = ga4event ?? SHOWTIMES_BUTTON_CLICK;

  hit_jan(ga4EventName, {
    ...(ga4data ?? {}),
    ...getReservationTrackingGA4(
      showtime,
      theater,
      movie,
      ga4EventName === SHOWTIMES_BUTTON_CLICK
    ),
    ...(ga4dataOverwrite ?? {})
  });
};

export const trackReservationImpression = ({
  showtime,
  theater,
  movie,
  ga4dataOverwrite
}: TheaterReservationTracking) => {
  hit_jan('showtimes_modal_open', {
    ...getReservationTrackingGA4(showtime, theater, movie, true),
    ...(ga4dataOverwrite ?? {})
  });
};

export default trackReservationClick;
