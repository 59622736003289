import { ROUTE } from 'common/configuration/constants';
import { RollerDateItemClickTracking } from 'common/constants/trackingEventsNames';
import { GA4Entity } from 'common/tracking/helpers';
import { hit as hit_jan } from 'common/tracking/jan';

const trackRollerDateItemClick = ({
  date,
  movie,
  theater
}: RollerDateItemClickTracking) => {
  hit_jan('internal_click', {
    position_name: 'calendar_carousel',
    site_route: ROUTE,
    movie_entity: movie
      ? GA4Entity(movie.internalId, movie.title)
      : 'undefined',
    theater_entity: theater
      ? GA4Entity(theater.internalId, theater.name)
      : 'undefined',
    filters_value: date
  });
};

export default trackRollerDateItemClick;
