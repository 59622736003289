import { ROUTE } from 'common/configuration/constants';
import slugify from 'common/tools/string/slugify';
import { GA4Entity } from 'common/tracking/helpers';
import { hit as hit_jan } from 'common/tracking/jan';

import { PartialTheater } from 'website/containers/showtimes/utils/types';

type OpenAccessibilityModalTracking = { theater?: PartialTheater };

export const trackOpenAccessibilityModal = ({
  theater
}: OpenAccessibilityModalTracking) => {
  hit_jan('accessibility_click', {
    theater_entity: theater
      ? GA4Entity(theater.internalId, theater.name)
      : 'undefined',
    theater_city: theater?.location?.city
      ? slugify(theater?.location?.city)
      : 'undefined',
    theater_region: theater?.location?.region
      ? slugify(theater.location.region)
      : 'undefined',
    theater_type: theater?.theaterCircuits
      ? GA4Entity(
          theater.theaterCircuits.internalId,
          theater.theaterCircuits.name
        )
      : '81007|independant',
    accessibility_provider: 'acces_libre',
    site_route: ROUTE
  });
};
