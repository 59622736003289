import { TheaterHeaderTooltipTracking } from 'common/constants/trackingEventsNames';
import { hit as hit_jan } from 'common/tracking/jan';

export const trackTooltipClick = ({ jan }: TheaterHeaderTooltipTracking) => {
  if (jan) hit_jan('theater_technologies_click', jan);
};

export const trackTooltipHover = ({ jan }: TheaterHeaderTooltipTracking) => {
  if (jan) hit_jan('theater_technologies_hover', jan);
};
