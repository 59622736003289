import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { headerAutocompleteSearch } from 'common/api/ElasticSearch';
import { prepare } from 'common/api/ElasticSearch.helper';
import Autocomplete from 'common/components/ui/Autocomplete';
import { AutocompleteRenderItemProps, Item } from 'common/components/ui/types';
import { BRAND_HAS_HEADER_SEARCH_AUTOCOMPLETE } from 'common/configuration/constants';
import { TrackingEventNames } from 'common/constants/trackingEventsNames';
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import debounce from 'common/tools/functions/debounce';
import { path } from 'common/tools/network/routing';
import trans from 'common/tools/translations/trans';

type HeaderSearchFormProps = {
  initialValue?: string;
  withFocus?: boolean;
};

const HeaderSearchForm = ({
  initialValue,
  withFocus = false
}: HeaderSearchFormProps) => {
  const [items, setItems] = useState<Array<Item>>([]);
  const [menuOpened, setMenuOpened] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const onMqStateChange = (mqState: number) => {
    if (menuOpened && mqState > 3) {
      setMenuOpened(!menuOpened);
    }
  };

  useEffect(() => {
    eventEmitter.on(EventsTypes.MQ_STATE, onMqStateChange);
  }, []);

  useEffect(() => {
    if (menuOpened) {
      window.document.getElementById('header-search-input')?.focus();
    }
  }, [menuOpened]);

  const onSelect = (
    _value?: string | null,
    item?: Item | null,
    index?: number | null
  ) => {
    if (item?.sponsored) {
      eventEmitter.emit(
        TrackingEventNames.ON_SELECT_AUTO_SUGGEST_SEARCH_EMERGENCE,
        { index, item, searchValue }
      );

      const location = item.itemVideoUrl ? item.itemVideoUrl : item.itemUrl;
      if (location) {
        document.location = location;
      }
    } else {
      eventEmitter.emit(TrackingEventNames.ON_SELECT_AUTO_SUGGEST_SEARCH, {
        index,
        item,
        searchValue
      });

      if (item?.itemUrl) {
        document.location = item.itemUrl;
      }
    }
  };

  const onEnter = (value?: string | null) => {
    // user pressed the "enter" key
    // if we have a value find form and submit it
    if (!value) {
      return;
    }
    const form = document.getElementById('search-header');
    if (form instanceof HTMLFormElement) form.submit();
  };

  const searchChanged = debounce(async (event, value) => {
    if (!value) {
      setItems([]);
      return;
    }

    const data = await headerAutocompleteSearch(value);

    const dataPrepared = prepare(data.results).slice(0, 7);

    dataPrepared.forEach(item => {
      if (item.sponsored) {
        eventEmitter.emit(TrackingEventNames.AUTO_SUGGEST_SEARCH_EMERGENCE, {
          item
        });
      }
    });

    dataPrepared.push({
      id: 'view_all',
      entitytype: 'view_all',
      title: value,
      title_original: trans('autocomplete.all-results', { term: value }),
      itemUrl: path('search') + '?q=' + value
    });

    setItems(dataPrepared);
    setSearchValue(value);
  });
  const buildMetadataSummary = (item: Item | null) => {
    if (!item?.meta) {
      return;
    }

    const resultArray: string[] = [];

    Object.keys(item.meta).forEach(property => {
      let joinedPropertyValue: string | undefined;

      const meta = item.meta?.[property];
      if (
        (property === 'director' || property === 'creator') &&
        meta &&
        meta.length
      ) {
        const value = Array.isArray(meta) ? meta.filter(i => i !== null) : [];

        if (value.length) {
          const transKey =
            'autocomplete.metadata-' + item.entitytype + '-' + property;
          joinedPropertyValue = trans(transKey, {
            [property]: value.join(', ')
          });
        } else {
          joinedPropertyValue = trans(
            'autocomplete.metadata-' + item.entitytype
          );
        }

        resultArray.unshift(joinedPropertyValue);
        return;
      }

      if (
        property === 'activities' &&
        meta &&
        Array.isArray(meta) &&
        meta.length
      ) {
        joinedPropertyValue = meta.slice(0, 3).join(', ');
      } else if (property === 'year') {
        // wrap years in parentheses
        joinedPropertyValue = meta !== 'null' ? '(' + meta + ')' : '';
      } else if (typeof meta === 'string') {
        joinedPropertyValue = meta;
      }

      if (joinedPropertyValue) {
        resultArray.push(joinedPropertyValue);
      }
    });

    const result = resultArray.join(' ');
    return result
      ? result.charAt(0).toUpperCase() + result.substring(1)
      : result;
  };

  const renderComplements = (item: Item | null) => {
    if (item?.comments) {
      return (
        <span className="autocomplete-result-complement">{item.comments}</span>
      );
    }

    const complements = [];

    if (
      item &&
      item.title &&
      item.title_original &&
      item.title !== item.title_original
    ) {
      complements.push(
        <span key="title" className="autocomplete-result-complement">
          {' '}
          {['movie', 'series'].indexOf(item.entitytype ?? '') > -1
            ? '(' + item.title_original + ')'
            : item.title_original}
        </span>
      );
    }

    complements.push(
      <span key="metadata" className="autocomplete-result-complement">
        {buildMetadataSummary(item)}
      </span>
    );

    return complements;
  };

  const renderItem = (
    item: Item | null,
    props: AutocompleteRenderItemProps<HTMLDivElement>,
    highlighted: boolean
  ) => {
    if (item?.entitytype === 'view_all') {
      return (
        <div
          className="autocomplete-submit-option"
          {...props}
          key={`item-${item.entitytype}-${item.id}`}
        >
          {item.title_original}
        </div>
      );
    }

    const itemClass = classNames(
      'autocomplete-result-item',
      { 'autocomplete-result-sponsored': item?.sponsored },
      { highlighted: highlighted }
    );

    const sponsorLabel = item?.sponsored
      ? trans('autocomplete.sponsored-link')
      : '';

    const renderBtn =
      item?.sponsored && item?.itemVideoUrl ? (
        <span className="button button-xs button-primary-full">
          {trans('moviepage_movie_details.show_trailer')}
        </span>
      ) : null;

    return (
      <div
        className={itemClass}
        data-sponsor={sponsorLabel}
        {...props}
        key={`item-${item?.entitytype}-${item?.id}`}
      >
        <img
          className="autocomplete-result-thumbnail"
          src={item?.thumbnail}
          alt={item?.title || item?.title_original}
          width="53"
          height="68"
        />
        <span className="autocomplete-result-text">
          <span className="autocomplete-result-title">
            {item?.title || item?.title_original}
          </span>
          {renderComplements(item)}
          {renderBtn}
        </span>
      </div>
    );
  };

  const inputProps = {
    className: 'header-search-input',
    id: 'header-search-input',
    name: 'q',
    type: 'text',
    autoComplete: 'off',
    placeholder: trans('autocomplete.placeholder')
  };
  const containerClasses = classNames('header-search-form-container', {
    is_open: menuOpened
  });
  return (
    <div className={containerClasses}>
      <Autocomplete
        initialValue={initialValue}
        focusOnFirstRender={withFocus}
        inputProps={inputProps}
        items={items}
        getItemValue={item => item?.title ?? item?.title_original ?? ''}
        renderItem={renderItem}
        renderMenu={(items, props) => {
          if (BRAND_HAS_HEADER_SEARCH_AUTOCOMPLETE) {
            let menuClass = 'autocomplete-results';
            if (items.length === 0) {
              menuClass += ' hidden';
            }
            return (
              <div className={menuClass} {...props} role="listbox">
                {items}
              </div>
            );
          }
          return null;
        }}
        onChange={searchChanged}
        onSelect={onSelect}
        onEnter={onEnter}
      />
      <button
        className="header-search-submit icon icon-search"
        type="submit"
        aria-label={trans('autocomplete.submit')}
        title={trans('autocomplete.submit')}
      />
    </div>
  );
};

export default HeaderSearchForm;
