import React from 'react';

import { BRAND_HAS_WHATSAPP_SHARE } from 'common/configuration/constants';

import ShareButton, { Network, Props as ShareButtonProps } from './ShareButton';

type Props = Omit<ShareButtonProps, 'url' | 'network'> & {
  link?: string;
};

const WhatsAppShareButton = ({ link, ...rest }: Props) => {
  const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(link ?? '')}`;

  if (!BRAND_HAS_WHATSAPP_SHARE) {
    return null;
  }

  return (
    <ShareButton {...rest} url={url} network={Network.WHATSAPP}>
      Whatsapp
    </ShareButton>
  );
};

export default WhatsAppShareButton;
