import Cookies from 'cookies-js';
import { DocumentNode } from 'graphql';
import { print } from 'graphql';

import { GRAPH_HOST } from 'common/configuration/constants';
import request from 'common/tools/network/request';

type RequestGraphParams = {
  query: string | DocumentNode;
  variables?: Record<string, any>;
};

export const requestGraph = <ResponseType>({
  query,
  variables
}: RequestGraphParams) => {
  // If a query is a DocumentNode, convert it to a string
  const queryString = typeof query === 'string' ? query : print(query);
  return request<ResponseType>(`${GRAPH_HOST}/v1/public`, {
    method: 'POST',
    payload: {
      query: queryString,
      variables
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('GraphToken')}`
    }
  }).then(response => {
    if (response.errors && response.errors.length) {
      throw new Error(response.errors[0].message);
    }
    return response;
  });
};

export default requestGraph;
