import classNames from 'classnames';
import React, { useState, SyntheticEvent } from 'react';

import useTestId from 'common/react/hooks/useTestId';
import noop from 'common/tools/noop';
import pad from 'common/tools/string/pad';
import trans from 'common/tools/translations/trans';

export type Size = 'small' | 'medium' | 'medium-large' | 'large' | 'xlarge';

export type UserRatingProps = {
  readOnly?: boolean;
  theme?: 'default' | 'yellow';
  value?: number | null;
  size?: Size;
  showDeleteRating?: boolean;
  onDelete?: (ratingValue: number) => void;
  onUpdate?: (newValue: number, previousValue: number) => void;
  onCreate?: (newValue: number) => void;
};

const sizeArray: Size[] = [
  'small',
  'medium',
  'medium-large',
  'large',
  'xlarge'
];
export const isSize = (candidate: any): candidate is Size =>
  sizeArray.includes(candidate);

const RATINGS = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];

//
// A component that renders a rating using stars
//
// The component is interactive: when hovered, the stars will "light" up.
// Also when clicked one of the callback will be triggered:
// - onDelete if clicked value is the same as the currently registered one
// - onCreate if there was no registered value
// - onUpdate if the clicked value and the registered value are different
//
// To get a non-interactive component, use the readOnly flag
//
function UserRating({
  onCreate = noop,
  onDelete = noop,
  onUpdate = noop,
  readOnly = false,
  showDeleteRating,
  size = 'small',
  theme = 'default',
  value = 0
}: UserRatingProps) {
  const [hoveredValue, setHoveredValue] = useState<number | null>(null);

  const handleClick = (ratingValue: number, e: SyntheticEvent): void => {
    if (readOnly) return;
    if (e) e.stopPropagation();
    if (value && ratingValue && value === ratingValue) {
      onDelete(ratingValue);
    } else if (value && ratingValue && value !== ratingValue) {
      onUpdate(ratingValue, value);
    } else if (!value && ratingValue) {
      onCreate(ratingValue);
    }
  };

  const handleMouseEnter = (ratingValue: number): void => {
    if (readOnly) return;
    setHoveredValue(ratingValue);
  };

  const handleMouseLeave = (): void => {
    if (readOnly) return;
    setHoveredValue(null);
  };

  let deleteRatingButton: JSX.Element | null = null;
  const doShowDeleteRating = showDeleteRating && !readOnly && value;

  if (doShowDeleteRating) {
    deleteRatingButton = (
      <span className="user-rating-del" onClick={onDelete.bind(null, value)}>
        <i className="icon icon-cross" />
      </span>
    );
  }
  const stars = RATINGS.map(ratingValue => {
    const referenceValue = hoveredValue !== null ? hoveredValue : value ?? 0;
    const isActive = ratingValue <= referenceValue;
    const ratingClass = classNames('rating-star tooltip-parent', {
      active: isActive
    });
    return (
      <div
        key={ratingValue}
        className={ratingClass}
        onClick={handleClick.bind(null, ratingValue)}
        onMouseEnter={handleMouseEnter.bind(null, ratingValue)}
        onMouseLeave={handleMouseLeave}
      >
        {!readOnly && (
          <span className="tooltip tooltip-dark tooltip-child">
            {`${ratingValue} ${trans(
              'rating.notescale-' +
                pad(`${(Math.round(ratingValue * 2) / 2) * 10}`, 2, '0')
            )}`}
          </span>
        )}
      </div>
    );
  });

  const dataTestId = useTestId('user-rating');

  return (
    <span
      {...dataTestId}
      className={classNames('user-rating-note', `theme-${theme}`, {
        'read-only': readOnly,
        'no-del': !doShowDeleteRating,
        [`user-rating-${size}`]: !!size
      })}
    >
      <span className="user-rating">
        {stars}
        {doShowDeleteRating ? deleteRatingButton : null}
      </span>
    </span>
  );
}

export default UserRating;
