import { ROUTE } from 'common/configuration/constants';
import { hasAdblock } from 'common/tools/adblock';
import { print as print_jan } from 'common/tracking/jan';

const trackBottomAds = () => {
  // ALLOCINE-10553
  const dataGA = window.dataLayerGA;
  const adblock_user = hasAdblock() ? 'adblock' : 'noblock';
  const content_id = dataGA ? dataGA.dimension33 : null;
  const entity_tag_id = dataGA ? dataGA.dimension79 : null;
  const site_route = dataGA ? dataGA.dimension10 : ROUTE;

  // ALLOCINE-13644
  print_jan('bottom_ads', {
    adblock_user,
    content_id,
    entity_tag_id,
    site_route
  });

  window.bottom_ads_detected = true;
};
export default trackBottomAds;
