/* Handle player footer and player list injection on scroll ...  */
import { Maybe } from 'types/graphql-api.generated';

import { getConsentState } from 'common/consent/Tcf';
import { bannerEmergence } from 'common/constants/Ads';
import { MQ_SMALL } from 'common/constants/MediaQueries';
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import * as events from 'common/tools/dom/events';
import hasTouch from 'common/tools/dom/getTouchBrowser';
import { getStateIndex } from 'common/tools/dom/mq-state';
import { onYScroll } from 'common/tools/dom/on-scroll';
import readAttribute from 'common/tools/dom/readAttribute';
import debounce from 'common/tools/functions/debounce';
import elementPartiallyVisible from 'common/tools/functions/elementPartiallyVisible';
import { path } from 'common/tools/network/routing';
import get from 'common/tools/objects/get';
import { runAfterDeferAssets } from 'common/tools/ui/load-assets';
import { print as print_jan, hit as hit_jan } from 'common/tracking/jan';
import { isString } from 'common/types';

import { closeBanner } from 'website/module/emergence/banner-bottom';

type PlayerData = {
  booster?: Maybe<boolean>;
  displayContext?: Maybe<boolean>;
  entityId?: Maybe<string>;
  entityType?: Maybe<string>;
  idMedia?: Maybe<string>;
  idMovie?: Maybe<string>;
  namePartner?: Maybe<string>;
  subtitle?: Maybe<string>;
  title?: Maybe<string>;
  tracking: {
    ga?: Maybe<Record<string, any>>;
    jan?: Maybe<Record<string, any>>;
  };
};

let debouncedCreatePlayer: () => void;
let playerPaused = false;
let playerCreated = false;

const textNode = document.createTextNode('');

const playPausePlayerOnScroll = (playerIframe: HTMLIFrameElement) => {
  if (!elementPartiallyVisible(playerIframe) && !playerPaused) {
    // the user scroll has hidden the player, try to pause it
    try {
      playerPaused = true;
      playerIframe.contentWindow?.postMessage('jwplayer-pause', '*');
      eventEmitter.emit(EventsTypes.PLAYER_EMERGENCE_PAUSED);
    } catch (e) {} // eslint-disable-line
  } else if (elementPartiallyVisible(playerIframe) && playerPaused) {
    // the user scroll has brought back the player on screen, restart playback if we paused it
    try {
      playerPaused = false;
      playerIframe.contentWindow?.postMessage('jwplayer-play', '*');
      eventEmitter.emit(EventsTypes.PLAYER_EMERGENCE_RESUMED);
    } catch (e) {} // eslint-disable-line
  }
};

const insertPlayer = (playerContainer: Element) => {
  const playerData = readAttribute<PlayerData, undefined>(
    playerContainer,
    'data-player'
  );

  if (playerData && !isString(playerData)) {
    const dateContainer = document.createElement('div');
    dateContainer.className =
      'titlebar-title titlebar-title-lg titlebar-top-border titlebar-page';
    const subtitle = textNode.cloneNode();
    subtitle.nodeValue = playerData.subtitle ?? '';
    dateContainer.appendChild(subtitle);

    const link = document.createElement('a');

    link.className = 'title-inter';
    const title = textNode.cloneNode();
    title.nodeValue = playerData.title ?? '';
    link.appendChild(title);

    // legacy player footer handling (should be moved to an agnostic key for idMovie)
    if (playerData.idMovie) {
      link.href = path('moviepage', { movie: playerData.idMovie });
    }

    if (playerData.entityId) {
      const seasonSeriesId = get(playerData, [
        'entity',
        'series',
        'internalId'
      ]);

      switch (playerData.entityType) {
        case 'movie':
          link.href = path('moviepage', { movie: playerData.entityId });
          break;

        case 'series':
          link.href = path('seriespage', { series: playerData.entityId });
          break;

        case 'season':
          if (seasonSeriesId) {
            link.href = path('seriespage_seasonpage', {
              series: seasonSeriesId,
              season: playerData.entityId
            });
          } else {
            link.href = 'noSeriesId';
          }
          break;

        default:
          link.href = '#';
      }
    }

    events.on(link, 'click', () => {
      if (playerData.tracking.jan)
        hit_jan(
          `${playerData.tracking.jan.eventName}_click`,
          playerData.tracking.jan
        );
    });

    const pageUrl = path('emergence_player', {
      namePartner: playerData.namePartner ?? undefined,
      video: playerData.idMedia ?? undefined
    });
    const playerUrl = `${pageUrl}?autoplay=1&booster=${playerData.booster}&displaycontext=${playerData.displayContext}`;

    const iframeContainer = document.createElement('div');
    iframeContainer.className = 'player-emergence-iframe-container';

    const linkContainer = document.createElement('div');
    linkContainer.className = 'text-center';

    const player = document.createElement('iframe');
    player.className = 'player-emergence-iframe';
    player.setAttribute('frameborder', '0');
    player.setAttribute('allowfullscreen', 'true');
    player.src = playerUrl;

    iframeContainer.appendChild(player);
    linkContainer.appendChild(link);

    if (dateContainer.textContent) {
      playerContainer.appendChild(dateContainer);
    }

    playerContainer.appendChild(linkContainer);
    playerContainer.appendChild(iframeContainer);

    // send GA print info
    if (playerData.tracking.jan)
      print_jan(playerData.tracking.jan.eventName, playerData.tracking.jan);

    // create and register a scroll watcher to pause the player if it is leaving the screen
    const debouncedPlayPausePlayer = debounce(() => {
      playPausePlayerOnScroll(player);
    });
    player.onload = () => {
      onYScroll(debouncedPlayPausePlayer);
    };
  }
};

const createPlayerOnScroll = (playerContainer: Element) => {
  if (!playerCreated && elementPartiallyVisible(playerContainer, 500)) {
    // unregister scroll down that shows the player
    events.off(window, 'scroll', debouncedCreatePlayer);
    insertPlayer(playerContainer);
    playerCreated = true;

    eventEmitter.emit(EventsTypes.PLAYER_EMERGENCE_INSERTED);
  }
};
const initPlayerFooter = (playerContainer: Element) => {
  debouncedCreatePlayer = debounce(() => {
    createPlayerOnScroll(playerContainer);
  });

  onYScroll(() => {
    debouncedCreatePlayer();
  });

  // in case it is already on screen
  runAfterDeferAssets(() => {
    createPlayerOnScroll(playerContainer);
  });

  eventEmitter.emit(EventsTypes.PLAYER_EMERGENCE_INITIALIZED);
};

export default async () => {
  const target = document.getElementById('player-emergence');
  if (!target) {
    return false;
  }

  await getConsentState(true);

  if (bannerEmergence && target && hasTouch()) {
    // never show banner
    if (getStateIndex() > MQ_SMALL) {
      initPlayerFooter(target);
      closeBanner();
    } else {
      eventEmitter.on(EventsTypes.PLAYER_EMERGENCE_INSERTED, () => {
        closeBanner();
      });

      initPlayerFooter(target);
    }
  } else {
    initPlayerFooter(target);
  }
};
