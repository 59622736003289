import socialActionTracking from 'common/tracking/socialActionTracking';

export const trackWantToSee = (
  eventAction: string | null,
  {
    context: { entity }
  }: {
    context: {
      entity: { legacyId: number; title: string; typename: string };
    };
  }
) => {
  if (
    entity.typename !== 'Movie' &&
    entity.typename !== 'Series' &&
    entity.typename !== 'Episode'
  ) {
    return;
  }

  socialActionTracking('want_to_see', eventAction, {
    ...entity,
    internalId: entity.legacyId
  });
};
