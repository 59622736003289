import { BRAND_HAS_PAYWALL } from 'common/configuration/constants';
import { getConsentForGoogle } from 'common/consent/Didomi';
import { getConsentState } from 'common/consent/Tcf';
import loadGoogleTagManager from 'common/third_party/googletagmanager';
import onUserAction from 'common/tools/ui/defer-on-user-action';

// This is in common folder even though it is dedicated to website pages
// Explanation to this is that the signup, sign-in and forgot password pages
// are technically in userspace scope but need this tracking (or maybe not?)
// todo fix that (those pages maybe do not belong to userspace)

export default async (gtm: boolean) => {
  // load Analytics after LOAD if possible, or on DOM READY if LOAD is too long :

  if (BRAND_HAS_PAYWALL) {
    getConsentState().then(() => {
      getConsentForGoogle();

      if (gtm) {
        // load GTM tracking after load only (if noAds or noGtm isn't required)
        onUserAction(loadGoogleTagManager);
      }
    });
  } else {
    if (gtm) {
      // load GTM tracking after load only (if noAds or noGtm isn't required)
      onUserAction(loadGoogleTagManager);
    }
  }
};
