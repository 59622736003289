/**
 * @param {string} email An email
 * @return {Promise} A promise
 */
import Cookies from 'cookies-js';

import { SSO_HOST } from 'common/configuration/constants';
import request from 'common/tools/network/request';

type Profile = {
  nickname: string;
  email?: string;
  gender?: 'male' | 'female' | 'undisclosed';
  birthDate?: string;
  cguValidated?: boolean;
};

export type Optins = {
  newsletterOptin?: boolean;
  notificationOptin?: boolean;
  sceanceOptin?: boolean;
  serieOptin?: boolean;
  streamingOptin?: boolean;
};

export type SocialConnectPayload = {
  token: string;
  profile?: {
    nickname?: string;
    cguValidation?: boolean;
  };
};

type DoRequestArgs = {
  endpoint: string;
  method?: string;
  payload?:
    | { [key: string]: string | boolean | number | undefined }
    | SocialConnectPayload;
  withCredentials?: boolean;
};

export enum connectionErrors {
  ACCOUNT_EXISTS = 'ACCOUNT_EXISTS',
  SOCIAL_NO_MAIL = 'SOCIAL_NO_MAIL'
}

const doRequest = ({
  endpoint,
  method = 'POST',
  payload,
  withCredentials = false
}: DoRequestArgs) => {
  const headers: { [key: string]: string } = {
    'Content-Type': 'application/json'
  };
  const token = Cookies.get('GraphToken');
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  const body: { [key: string]: any } = {
    method,
    payload,
    headers
  };

  if (withCredentials) {
    body['credentials'] = 'include';
  }
  return request(`${SSO_HOST}${endpoint}`, body);
};
export const isEmailValid = (email: string) =>
  doRequest({
    endpoint: '/api/account/validity',
    payload: { email }
  });

export const connectWithGoogle = (payload: SocialConnectPayload) =>
  doRequest({
    endpoint: '/api/account/connect/google',
    payload,
    withCredentials: true
  });

export const connectWithFacebook = (payload: SocialConnectPayload) =>
  doRequest({
    endpoint: '/api/account/connect/facebook',
    payload,
    withCredentials: true
  });

export const connectWithLoginPassword = (login: string, password: string) =>
  doRequest({
    endpoint: '/api/account/connect',
    payload: {
      email: login,
      password: password
    },
    withCredentials: true
  });

export const getNewsletterOptins = () =>
  doRequest({
    endpoint: '/api/account/newsletter-optins',
    method: 'GET'
  });

export const disableAccount = () =>
  doRequest({
    endpoint: '/api/account',
    method: 'DELETE'
  });

export const updateNewsletterOptins = (optins: Optins) =>
  doRequest({
    endpoint: '/api/account/newsletter-optins',
    payload: optins
  });

export const getDisqusToken = () =>
  doRequest({
    endpoint: '/api/disqus/mytoken',
    method: 'GET'
  });

export const changePassword = (oldPassword: string, newPassword: string) =>
  doRequest({
    endpoint: '/api/account/change-password',
    payload: {
      password: oldPassword,
      'new-password': newPassword
    }
  });

export const updateProfile = ({
  nickname,
  email,
  gender,
  birthDate,
  cguValidated
}: Partial<Profile>) =>
  doRequest({
    endpoint: '/api/account/update-profile',
    payload: {
      ...(nickname ? { nickname } : {}),
      ...(email ? { email } : {}),
      ...(gender ? { gender } : {}),
      ...(birthDate ? { birthDate } : {}),
      ...(cguValidated !== undefined ? { cguValidated } : {})
    }
  });

export const followFacebookFriends = (facebookToken: string) =>
  doRequest({
    endpoint: '/api/account/follow-fb-friends',
    payload: {
      token: facebookToken
    }
  });

export const resendActivationEmail = () =>
  doRequest({
    endpoint: '/api/account/resend-activation-email'
  });
