import { Maybe } from 'types/graphql-api.generated';

import { ROUTE } from 'common/configuration/constants';
import { GA4Entity } from 'common/tracking/helpers';
import { hit as hit_jan } from 'common/tracking/jan';

export type SocialActionEntity = {
  id?: string;
  internalId?: Maybe<number>;
  title?: Maybe<string>;
  typename: string;
  userEntityLeafId?: Maybe<string>;
  userEntityLeafTypename?: string;
};

const typenameToEntityKey: Record<string, string> = {
  Episode: 'series_episode_entity',
  Movie: 'movie_entity',
  News: 'content_entity',
  Person: 'person_entity',
  Season: 'series_season_entity',
  Series: 'series_entity',
  Theater: 'theater_entity',
  Video: 'video_entity'
};

// Function to get entity data
const getEntityData = (entity?: SocialActionEntity) => {
  if (entity) {
    const { internalId, title, typename } = entity;
    const entityId = GA4Entity(internalId, title);
    const entityKey = typenameToEntityKey[typename] || '';

    return entityKey !== '' ? { [entityKey]: entityId } : {};
  }

  return {};
};

// Function to track social action
const trackSocialAction = (
  socialActionType: string,
  eventAction: string | null,
  entityData: object
) => {
  hit_jan('social_action_click', {
    social_action_type: socialActionType,
    ...(eventAction === null
      ? {}
      : { social_action_update_type: eventAction ?? 'undefined' }),
    ...entityData,
    site_route: ROUTE
  });
};

const socialActionTracking = (
  socialActionType: string,
  eventAction?: string | null,
  entity?: SocialActionEntity | SocialActionEntity[]
) => {
  const entities = Array.isArray(entity) ? entity : [entity];

  entities.forEach(entity => {
    const entityData = getEntityData(entity);
    trackSocialAction(socialActionType, eventAction ?? null, entityData);
  });
};

export default socialActionTracking;
