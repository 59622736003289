import { TrackingEventNames } from 'common/constants/trackingEventsNames';
import eventEmitter from 'common/services/events/eventEmitter';
import {
  trackAddCollectionEntities,
  trackRemoveCollectionEntities
} from 'common/tracking/collectionTracking';

import { trackOpenAccessibilityModal } from 'website/tracking/listener/accessLibreTracking';
import { trackAffinityTooltip } from 'website/tracking/listener/affinityTooltipTracking';
import {
  trackAutoSuggestSearchEmergence,
  trackOnSelectAutoSuggestSearch,
  trackOnSelectAutoSuggestSearchEmergence
} from 'website/tracking/listener/autocompleteTracking';
import trackBottomAds from 'website/tracking/listener/bottomAdsTracking';
import { gtmTrackOpinion } from 'website/tracking/listener/gtm.opinionTracking';
import { gtmTrackWantToSee } from 'website/tracking/listener/gtm.wantToSeeTracking';
import { trackOpinion } from 'website/tracking/listener/opinionTracking';
import trackReservationClick, {
  trackReservationImpression
} from 'website/tracking/listener/reservationTracking';
import trackRollerDateItemClick from 'website/tracking/listener/rollerDateItemTracking';
import trackShowtimeFilterButtonClick from 'website/tracking/listener/showtimeFilterButtonTracking';
import { trackUserTheater } from 'website/tracking/listener/theaterTracking';
import {
  trackTooltipClick,
  trackTooltipHover
} from 'website/tracking/listener/tooltipTracking';
import { trackWantToSee } from 'website/tracking/listener/wantToSeeTracking';

export type EventAction = 'add' | 'remove';

/**
 * Tracking initialization
 * @returns {void}
 */
const initializeTracking = (): void => {
  const {
    ADD_OPINION_EVENT,
    ADD_USER_THEATER_EVENT,
    ADD_WANT_TO_SEE_EVENT,
    AFFINITY_TOOLTIP_EVENT,
    AUTO_SUGGEST_SEARCH_EMERGENCE,
    DELETE_OPINION_EVENT,
    DELETE_WANT_TO_SEE_EVENT,
    ON_SELECT_AUTO_SUGGEST_SEARCH,
    ON_SELECT_AUTO_SUGGEST_SEARCH_EMERGENCE,
    OPEN_ACCESSIBILITY_MODAL,
    REMOVE_USER_THEATER_EVENT,
    THEATER_RESERVATION_LINK_CLICK_EVENT,
    TRACKING_ROLLER_DATE_ITEM_HANDLE_CLICK,
    TRACKING_SHOWTIME_FILTER_BUTTON_HANDLE_CLICK,
    UPDATE_OPINION_EVENT
  } = TrackingEventNames;

  eventEmitter.on(ADD_OPINION_EVENT, trackOpinion.bind(null, 'add'));
  eventEmitter.on(ADD_OPINION_EVENT, gtmTrackOpinion.bind(null, 'add'));
  eventEmitter.on(DELETE_OPINION_EVENT, trackOpinion.bind(null, 'remove'));
  eventEmitter.on(DELETE_OPINION_EVENT, gtmTrackOpinion.bind(null, 'remove'));
  eventEmitter.on(UPDATE_OPINION_EVENT, trackOpinion.bind(null, 'edit'));
  eventEmitter.on(UPDATE_OPINION_EVENT, gtmTrackOpinion.bind(null, 'edit'));

  eventEmitter.on(ADD_WANT_TO_SEE_EVENT, (...args) =>
    // @ts-expect-error type args
    trackWantToSee('add', ...args)
  );
  eventEmitter.on(ADD_WANT_TO_SEE_EVENT, gtmTrackWantToSee.bind(null, 'add'));
  eventEmitter.on(
    DELETE_WANT_TO_SEE_EVENT,
    trackWantToSee.bind(null, 'remove')
  );
  eventEmitter.on(
    DELETE_WANT_TO_SEE_EVENT,
    gtmTrackWantToSee.bind(null, 'remove')
  );

  eventEmitter.on(ADD_USER_THEATER_EVENT, () => trackUserTheater('add'));
  eventEmitter.on(REMOVE_USER_THEATER_EVENT, () => trackUserTheater('remove'));

  eventEmitter.on(THEATER_RESERVATION_LINK_CLICK_EVENT, trackReservationClick);

  eventEmitter.on(
    TRACKING_ROLLER_DATE_ITEM_HANDLE_CLICK,
    trackRollerDateItemClick
  );
  eventEmitter.on(
    TRACKING_SHOWTIME_FILTER_BUTTON_HANDLE_CLICK,
    trackShowtimeFilterButtonClick
  );

  eventEmitter.on(AFFINITY_TOOLTIP_EVENT, trackAffinityTooltip);

  eventEmitter.on(OPEN_ACCESSIBILITY_MODAL, trackOpenAccessibilityModal);

  eventEmitter.on(
    ON_SELECT_AUTO_SUGGEST_SEARCH,
    trackOnSelectAutoSuggestSearch
  );
  eventEmitter.on(
    ON_SELECT_AUTO_SUGGEST_SEARCH_EMERGENCE,
    trackOnSelectAutoSuggestSearchEmergence
  );
  eventEmitter.on(
    AUTO_SUGGEST_SEARCH_EMERGENCE,
    trackAutoSuggestSearchEmergence
  );
  eventEmitter.on(
    TrackingEventNames.ON_CLICK_THEATER_TOOLTIP,
    trackTooltipClick
  );
  eventEmitter.on(
    TrackingEventNames.ON_HOVER_THEATER_TOOLTIP,
    trackTooltipHover
  );
  eventEmitter.on(TrackingEventNames.BOTTOM_ADS_DETECTED, trackBottomAds);
  eventEmitter.on(
    TrackingEventNames.ADD_ENTITIES_TO_COLLECTION_EVENT,
    trackAddCollectionEntities
  );
  eventEmitter.on(
    TrackingEventNames.REMOVE_ENTITIES_FROM_COLLECTION_EVENT,
    trackRemoveCollectionEntities
  );
  eventEmitter.on(
    TrackingEventNames.OPEN_SHOWTIME_MODAL,
    trackReservationImpression
  );
};

export default initializeTracking;
