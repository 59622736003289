import { Maybe } from 'graphql/jsutils/Maybe';

import type { AffinityScoreReason } from 'common/constants/Affinities';

import type { SeasonStatus } from './reducers/data/types';

// Using string enum because it will serialize in a manner usable by JS files
export enum EntityTypename {
  Movie = 'Movie',
  Series = 'Series',
  Season = 'Season',
  Episode = 'Episode',
  Program = 'Program',
  UserReview = 'UserReview',
  Theater = 'Theater'
}

// Using string enum because it will serialize in a manner usable by JS files
export enum SocialActionTypename {
  Opinion = 'Opinion',
  WantToSee = 'WantToSee',
  SeenIt = 'SeenIt',
  Helpful = 'Helpful',
  Unhelpful = 'Unhelpful'
}

export type UserAffinity = {
  reason: AffinityScoreReason[] | null;
  affinityScore: number | null;
};

type Base<T extends string = string> = {
  typename: T;
  id: string;
  legacyId: number;
};

type BaseSocialAction<T extends SocialActionTypename> = Base<T> & {
  date: Date;
  relatedEntity: string;
  isDeleted: boolean;
};

export type Movie = Base<EntityTypename.Movie> & {
  isComingSoon: boolean;
  poster: string | null;
  productionYear: number | null;
  releaseDate: string | null;
  title: string;
  userAffinity?: UserAffinity;
  wantToSeeCount: number | null;
  hasMandatoryReview: boolean;
};

export type Series = Base<EntityTypename.Series> & {
  title: string;
  poster: string | null;
  isComingSoon: boolean;
  releaseDate: string | null;
  seasons: string[];
  userAffinity?: UserAffinity;
};

export type Season = Base<EntityTypename.Season> & {
  number: number;
  status: SeasonStatus;
};

export type Episode = Base<EntityTypename.Episode> & {
  title: string;
};

export type Program = Base<EntityTypename.Program> & {
  title: string;
};

export type UserReview = Base<EntityTypename.UserReview>;

export type Theater = Base<EntityTypename.Theater> & {
  name: Maybe<string>;
  internalId: Maybe<string>;
};

export type WantToSee = BaseSocialAction<SocialActionTypename.WantToSee>;

export type SeenIt = BaseSocialAction<SocialActionTypename.SeenIt>;

export type Helpful = BaseSocialAction<SocialActionTypename.Helpful>;

export type Unhelpful = BaseSocialAction<SocialActionTypename.Unhelpful>;

export type Opinion = BaseSocialAction<SocialActionTypename.Opinion> & {
  rating: number | null;
  review: string | null;
  status: string | null;
  value?: string | null;
};

export type Entity =
  | Movie
  | Series
  | Season
  | Episode
  | Program
  | UserReview
  | Theater;
export type SocialAction = WantToSee | SeenIt | Helpful | Unhelpful | Opinion;
export type SuggestableEntity = Movie | Series;

export const isEntity = (candidate: any): candidate is Entity =>
  !!(candidate && candidate.typename in EntityTypename);

export const isMovie = (candidate: any): candidate is Movie =>
  candidate.typename === EntityTypename.Movie;

export const isSeason = (candidate: any): candidate is Season =>
  candidate.typename === EntityTypename.Season;

export const isSeries = (candidate: any): candidate is Series =>
  candidate.typename === EntityTypename.Series;

export const isEpisode = (candidate: any): candidate is Episode =>
  candidate.typename === EntityTypename.Episode;

export const isProgram = (candidate: any): candidate is Program =>
  candidate.typename === EntityTypename.Program;

export const isTheater = (candidate: Entity): candidate is Theater =>
  candidate.typename === EntityTypename.Theater;

export const isSuggestableEntity = (
  candidate: any
): candidate is SuggestableEntity => isMovie(candidate) || isSeries(candidate);

export const isSocialAction = (candidate: any): candidate is SocialAction =>
  !!(candidate && candidate.typename in SocialActionTypename);

export const isHelpful = (candidate: any): candidate is Helpful =>
  candidate.typename === SocialActionTypename.Helpful;

export const isUnhelpful = (candidate: any): candidate is Unhelpful =>
  candidate.typename === SocialActionTypename.Unhelpful;
