import { TrackingEventNames } from 'common/constants/trackingEventsNames';
import eventEmitter from 'common/services/events/eventEmitter';

export const trackAffinityTooltip = ({
  eventType
}: {
  eventType: 'hover' | 'touch';
}): void => {
  // the print tracking for tooltip display on mouse hover
  // have to be call once time only
  // not the case for touch event
  if (eventType === 'hover') {
    eventEmitter.off(
      TrackingEventNames.AFFINITY_TOOLTIP_EVENT,
      trackAffinityTooltip
    );
  }
};
