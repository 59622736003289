import { Item } from 'common/components/ui/types';
import { ROUTE } from 'common/configuration/constants';
import {
  AutoSuggestSearchEmergenceTracking,
  OnSelectAutoSuggestSearchTracking
} from 'common/constants/trackingEventsNames';
import { GA4Entity } from 'common/tracking/helpers';
import { hit as hit_jan, print as print_jan } from 'common/tracking/jan';

const buildGA4Tracking = (item: Item) => {
  const trackingData: Record<string, string | number> = {
    brand_campaign_id: item.campaignId ?? 'undefined',
    brand_campaign_name: item.campaignName?.toLowerCase() ?? 'undefined',
    brand_campaign_position: 'sponsored_autocomplete',
    brand_campaign_position_type: 'search',
    brand_campaign_type: 'sponsored_autocomplete',
    site_route: ROUTE
  };

  if (item.entitytype === 'movie') {
    trackingData['movie_entity'] =
      GA4Entity(item.id, item.title) ?? 'undefined';
  } else if (item.entitytype === 'series') {
    trackingData['series_entity'] =
      GA4Entity(item.id, item.title) ?? 'undefined';
  }

  return trackingData;
};

const ENTITY_TYPES = {
  MOVIE: 'movie',
  SERIES: 'series',
  PERSON: 'person',
  THEATER: 'theater',
  VIEW_ALL: 'view_all'
};

const getEntityData = (item: Item) => {
  switch (item.entitytype) {
    case ENTITY_TYPES.MOVIE:
      return { movie_entity: GA4Entity(item.id, item.title) };
    case ENTITY_TYPES.SERIES:
      return { series_entity: GA4Entity(item.id, item.title) };
    case ENTITY_TYPES.PERSON:
      return { person_entity: GA4Entity(item.id, item.title) };
    case ENTITY_TYPES.THEATER:
      return { theater_entity: GA4Entity(item.id, item.title) };
    default:
      return {};
  }
};

const hitGA4SearchClick = ({
  searchValue,
  item,
  index
}: OnSelectAutoSuggestSearchTracking) => {
  const entityData = getEntityData(item);
  const theme =
    item.entitytype === ENTITY_TYPES.VIEW_ALL ? 'all_result' : item.entitytype;

  hit_jan('search_click', {
    autosuggest_query: searchValue,
    autosuggest_theme: theme,
    autosuggest_position: index,
    ...entityData
  });
};

export const trackOnSelectAutoSuggestSearch = (
  args: OnSelectAutoSuggestSearchTracking
) => hitGA4SearchClick(args);

export const trackOnSelectAutoSuggestSearchEmergence = (
  args: OnSelectAutoSuggestSearchTracking
) => {
  const { item } = args;

  hit_jan('emergence_click', buildGA4Tracking(item));

  hitGA4SearchClick(args);
};

export const trackAutoSuggestSearchEmergence = ({
  item
}: AutoSuggestSearchEmergenceTracking) => {
  print_jan('emergence', buildGA4Tracking(item));
};
