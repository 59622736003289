import React from 'react';
import { createRoot } from 'react-dom/client';

import readAttribute from 'common/tools/dom/readAttribute';

import ReleaseCountdown, {
  EmergenceTracking,
  isReleasedOn,
  ReleasedOn
} from 'website/components/emergence/ReleaseCountdown';

export default function loadEmergenceCountdown() {
  const anchors = document.getElementsByClassName('js-emergence-countdown');

  if (!anchors.length) {
    return false;
  }

  for (const anchor of anchors) {
    const entityId = readAttribute<string, undefined>(anchor, 'data-entity-id');
    const entityParentId = readAttribute<string, undefined>(
      anchor,
      'data-entity-parent-id'
    );
    const entityVideoId = readAttribute<string, undefined>(
      anchor,
      'data-entity-video-id'
    );
    const entityRelease = readAttribute<string, undefined>(
      anchor,
      'data-entity-release'
    );
    const entityProviderName = readAttribute<string, undefined>(
      anchor,
      'data-entity-provider-name'
    );
    const entityProviderLogo = readAttribute<string, undefined>(
      anchor,
      'data-entity-provider-logo'
    );
    const entityType = readAttribute<string, undefined>(
      anchor,
      'data-entity-type'
    );
    const entityRedirect = readAttribute<string, undefined>(
      anchor,
      'data-entity-redirect'
    );
    const releasedOn = readAttribute<ReleasedOn, undefined>(
      anchor,
      'data-released-on'
    );
    const textBeforeRelease = readAttribute<string, undefined>(
      anchor,
      'data-text-before-release'
    );
    const textAfterRelease = readAttribute<string, undefined>(
      anchor,
      'data-text-after-release'
    );
    const ctaTextBeforeRelease = readAttribute<string, undefined>(
      anchor,
      'data-cta-text-before-release'
    );
    const ctaTextAfterRelease = readAttribute<string, undefined>(
      anchor,
      'data-cta-text-after-release'
    );

    const tracking = readAttribute<EmergenceTracking, undefined>(
      anchor,
      'data-jan'
    );

    const root = createRoot(anchor);
    root.render(
      <ReleaseCountdown
        ctaTextAfterRelease={ctaTextAfterRelease}
        ctaTextBeforeRelease={ctaTextBeforeRelease}
        entityId={entityId}
        entityParentId={entityParentId}
        entityProviderLogo={entityProviderLogo}
        entityProviderName={entityProviderName}
        entityRedirect={entityRedirect}
        entityRelease={entityRelease}
        entityType={entityType}
        entityVideoId={entityVideoId}
        releasedOn={isReleasedOn(releasedOn) ? releasedOn : 'THEATER'}
        textAfterRelease={textAfterRelease}
        textBeforeRelease={textBeforeRelease}
        tracking={typeof tracking === 'object' ? tracking : undefined}
      />
    );
  }
}
